import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Layout } from '../components/Layout'
import { alignVertical } from '../styles'

export const query = graphql`
	query {
		microcmsMeta {
			title
			description
		}
	}
`

const NotFoundPage = ({ data: { microcmsMeta } }: { [key: string]: any }) => (
	<Layout
		helmet={{
			title: microcmsMeta.title,
			description: microcmsMeta.description,
		}}
		children={
			<>
				<PageWrapper>
					<h2>404</h2>
					<h1>NOT FOUND</h1>
					<ToTop to='/'>Jump to Top</ToTop>
				</PageWrapper>
			</>
		}
	/>
)

export default NotFoundPage

const PageWrapper = styled.div`
	min-height: calc(100vh - 94px);
	${alignVertical};
	h2 {
		font-size: 18px;
		margin-bottom: 24px;
	}
	h1 {
		font-size: 18px;
		margin-bottom: 36px;
	}
`

const ToTop = styled(Link)`
	border: 1px solid;
	padding: 10px 20px;
	border-radius: 40px;
	font-size: 14px;
`
